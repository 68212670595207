<template>
  <div class="box">
    <div class="zhu_zhaung_tu_box">
      <v-chart :options="options" autoresize/>
    </div>
  </div>
</template>

<script>

export default {
  name: "page2",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    ispreview: Boolean
  },
  data() {
    return {
      title: '',
      options: {
        title: {
          text: "巴林右旗大板镇土地流转图",
          right: "3%",
          top: '3.3%',
          show: true,
          textStyle: {
            color: "#82fefe"
          }
        },
        grid: {},
        legend: {
          textStyle: {
            color: "#fff"
          }
        },
        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
              fontSize: 14
            },
            rotate: -45
          }
        },
        yAxis: {
          type: "value",
          data: [],
          name: '',
          nameTextStyle: {
            color: "#fff",
            nameLocation: "start",
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#fff",
              fontSize: 20
            }
          }
        },
        series: [
          {
            data: [],
            type: "bar",
            barGap: "0%",
            barWidth: 25,
            color: '#82fefe',
            itemStyle: {
              normal: {
                // color: function(params) {
                //   var colorList = [
                //     "rgb(13, 246, 94)",
                //     "rgb(13, 246, 94)",
                //     "rgb(13, 246, 94)",
                //     "rgb(13, 246, 94)",
                //     "rgb(13, 246, 94)",
                //     "rgb(13, 246, 94)",
                //     "rgb(13, 246, 94)",
                //     "rgb(13, 246, 94)",
                //     "rgb(13, 246, 94)",
                //     "rgb(13, 246, 94)",
                //     "rgb(13, 246, 94)",
                //     "rgb(13, 246, 94)",
                //     "rgb(13, 246, 94)",
                //     "rgb(13, 246, 94)",
                //   ]; 
                //   return colorList[params.dataIndex];
                // },

                // color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                //   [
                //     {
                //       offset: 0,
                //       color: "rgba(0,244,255,1)" // 0% 处的颜色
                //     },
                //     {
                //       offset: 1,
                //       color: "rgba(0,77,167,1)" // 100% 处的颜色
                //     }
                //   ],
                //   false
                // ),

                color: '#82fefe',
                shadowColor: "rgba(0,160,221,1)",
                //模糊细数
                shadowBlur: 4
              }
            },
            // 标签
            label: {
              show: true,
              position: "top",
              distance: 10,
              fontSize: 16,
              color: '#82fefe',
              fontWeight: 500
            }
          }
        ],
        // 提示
        tooltip: {
          trigger: "item",
          show: true,
          textStyle: {
            color: '#fff',
            fontSize: 16
          }
        }
      },
      optionsStyle: {}, // 样式
      optionsData: {}, // 数据
      optionsSetup: {},
      flagInter: null
    };
  },
  watch: {
    value(val) {
      console.log('土地流转 : ', val.title.text);
      this.options.title.text = val.title.text
      this.options.xAxis.data = val.x
      this.options.yAxis.data = val.y
      this.options.series[0].data = val.y
      this.options.yAxis.name = val.yName
    }
  },
  mounted() {
    // let val = JSON.parse(this.$route.params.val)
    // this.options.title.text = val.title
    // this.options.xAxis.data = val.x
    // this.options.yAxis.data = val.y
    // this.options.yAxis.name = val.yName
    // this.options.series[0].data = val.y
  },
  methods: {
    
  }
};
</script>

<style scoped lang="scss">
.box {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: 100% 100%;
}

.zhu_zhaung_tu_box {
  width: 100%;
  height: 100%;
  position: relative;
}

.echarts {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
