<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToMenuPage()">巴林右旗土地确权信息</div> 
    </div>

    <div class="content_box">
      <div class="left_box">
        <div class="title"><span>地块属性</span></div>

        <div class="list_box color_fff">
          <div class="w_full mb_5">
            <span class="w_150 float_left fs_22 main_color ml_20">土地利用类型:</span>
            <span class="w_140 fs_22 color_fff ml_20 font_number">{{objData.tdlylx}}</span>
          </div>
           <div class="w_full mb_5">
            <span class="w_150 float_left fs_22 main_color ml_20">地块类别:</span>
            <span class="w_140 fs_22 color_fff ml_20 font_number">{{objData.dklb}}</span>
          </div>
           <div class="w_full mb_5">
            <span class="w_150 float_left fs_22 main_color ml_20">土地用途:</span>
            <span class="w_140 fs_20 color_fff ml_20 font_number">{{objData.tdyt}}</span>
          </div>
           <div class="w_full mb_5">
            <span class="w_150 float_left fs_22 main_color ml_20">是否基本农田:</span>
            <span class="w_140 fs_20 color_fff ml_20 font_number">{{objData.sfjbnt}}</span>
          </div>
           <div class="w_full mb_5">
            <span class="w_150 float_left fs_22 main_color ml_20">实测面积:</span>
            <span class="w_140 fs_20 color_fff ml_20 font_number">{{objData.scmj}}平方米</span>
          </div>
           <div class="w_full mb_5">
            <span class="w_150 float_left fs_22 main_color ml_20">地块东至:</span>
            <span class="w_140 fs_20 color_fff ml_20">{{objData.dkdz}}</span>
          </div>
           <div class="w_full mb_5">
            <span class="w_150 float_left fs_22 main_color ml_20">地块西至:</span>
            <span class="w_140 fs_20 color_fff ml_20">{{objData.dkxz}}</span>
          </div>
           <div class="w_full mb_5">
            <span class="w_150 float_left fs_22 main_color ml_20">地块南至:</span>
            <span class="w_140 fs_20 color_fff ml_20">{{objData.dknz}}</span>
          </div>
           <div class="w_full mb_5">
            <span class="w_150 float_left fs_22 main_color ml_20">地块北至:</span>
            <span class="w_140 fs_20 color_fff ml_20">{{objData.dkbz}}</span>
          </div>
           <div class="w_full mb_5">
            <span class="w_150 float_left fs_22 main_color ml_20">地块备注信息:</span>
            <span class="w_140 fs_20 color_fff ml_20">{{objData.dkbzxx}}</span>
          </div>
           <div class="w_full mb_5">
            <span class="w_150 float_left fs_22 main_color ml_20">指界人姓名:</span>
            <span class="w_140 fs_20 color_fff ml_20">{{objData.zjrxm}}</span>
          </div>
           <div class="w_full mb_5">
            <span class="w_150 float_left fs_22 main_color ml_20">KJZB:</span>
            <span class="w_140 fs_20 color_fff ml_20 font_number">{{objData.kjzb}}</span>
          </div>
           <div class="w_full mb_5">
            <span class="w_150 float_left fs_22 main_color ml_20">实测面积:</span>
            <span class="w_140 fs_20 color_fff ml_20 font_number">{{objData.scmjm}}亩</span>
          </div>
           <div class="w_full mb_5">
            <span class="w_150 float_left fs_22 main_color ml_20">地理等级:</span>
            <span class="w_140 fs_20 color_fff ml_20 font_number">{{objData.dldj}}</span>
          </div>

        </div>
      </div> 

      <div class="right_box">
        <div class="map_box">
          <tu-di-liu-zhuan @showObj="showObj" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BaLinYouQiMap from '../maps/baLinYouQiMap.vue'
  import BingTu from '../charts/bing-tu.vue'
  import ZhuZhaungTu from '../charts/zhu-zhaung-tu-tu-di.vue'
  import TuDiLiuZhuan from '../maps/tu-di-liu-zhuan.vue'

  export default {
    name: 'showPage1',
    components: { 
      BaLinYouQiMap, 
      BingTu, 
      ZhuZhaungTu,
      TuDiLiuZhuan,
    },
    data() {
      return {

        objData: {}
      }
    },
    mounted() {

    },
    methods: {
      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.push({
          path: "/bigscreen/menuPage"
        })
      },

      showObj(val) {
        this.objData = val
      }
    }
  }
</script>

<style scoped lang="less">
  @import url('../../../../assets/css/bigscreen.less');
  .activeColor {color: rgb(13, 246, 94) !important; }

  
  .left_box {
    width: 36%;
    height: 100% !important;

    .title { 
      width: 100%;
      height: 5%;
      text-align: center;
      position: relative;
      
      span {
        font-weight: bold;
        font-size: 18px;
        color: #82fefe;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .list_box {
      width: 100%;
      height: 95%;
      overflow: auto;
      padding: 10px;

      .item {
        width: 100%;
        font-size: 20px;
        color: #fff;
        padding: 10px;
        border: 1px solid #fff;
        border-radius: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        // background-color: #2567f6;

        span { width: 60px; }
        .name { width: 30%; cursor: pointer; }   
        .value1 { width: 25%; }
        .value2 { width: 25%; }
        .value3 { width: 20%; }
        .value4 { width: 20%; }
      }
    }
    .list_box::-webkit-scrollbar {
      width: 0px;
      height: 100%;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {                 // 表示纵向滚动块
      border-radius: 10px;
      background: #2366fc;
    }
  }

  .right_box {
    width: 60%;
    height: 100%;

    .map_box {
      width: 100%;
      height: 100%;
      .show_map_btn { cursor: pointer; }
    }
  }  

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    
  }
</style>