<template>
  <div class="ba_lin_you_qi_map">
    <!-- <v-chart :options="options" autoresize @click="clickMap" /> -->
    <v-chart :options="options" autoresize @click="clickMap" />
  </div>
</template>

<script>
import * as echarts from "echarts";
import '../../../../assets/js/map/tu-di-liu-zhuan.js'

let arr = [
  {
    type: 'Feature',
    id: 1,
    properties: {
      bsm: 10000000,
      ysdm: '211011',
      dkbm: '1504231032080300001',
      dkmc: '树林北',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 1766.67,
      dkdz: '房屋',
      dkxz: '林地',
      dknz: '道路',
      dkbz: '林地',
      dkbzxx: null,
      zjrxm: '刘国臣',
      kjzb: '384/381/382/383',
      scmjm: 2.65,
      dldj: '01',
      name: '树林北'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 2,
    properties: {
      bsm: 10000001,
      ysdm: '211011',
      dkbm: '1504231032080300004',
      dkmc: '树林北',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 7146.67,
      dkdz: '刘海龙',
      dkxz: '林地',
      dknz: '林地',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘国臣',
      kjzb: '138/139/142/143/141',
      scmjm: 10.72,
      dldj: '01',
      name: '树林北'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 3,
    properties: {
      bsm: 10000002,
      ysdm: '211011',
      dkbm: '1504231032080300003',
      dkmc: '树林北',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 4220,
      dkdz: '刘国臣',
      dkxz: '刘国臣',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘海龙',
      kjzb: '138/131/130/140/139',
      scmjm: 6.33,
      dldj: '01',
      name: '树林北'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 4,
    properties: {
      bsm: 10000003,
      ysdm: '211011',
      dkbm: '1504231032080300002',
      dkmc: '树林北',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 12233.33,
      dkdz: '房屋',
      dkxz: '刘海龙',
      dknz: '林地',
      dkbz: '房屋',
      dkbzxx: null,
      zjrxm: '刘国臣',
      kjzb: '134/135/136/137/130/131/132/133',
      scmjm: 18.35,
      dldj: '01',
      name: '树林北'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 5,
    properties: {
      bsm: 10000004,
      ysdm: '211011',
      dkbm: '1504231032080300005',
      dkmc: '树林北',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 7700,
      dkdz: '林地',
      dkxz: '林地',
      dknz: '林地',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '孙占武',
      kjzb: '385/386/387/388',
      scmjm: 11.55,
      dldj: '01',
      name: '树林北'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 6,
    properties: {
      bsm: 10000005,
      ysdm: '211011',
      dkbm: '1504231032080300013',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '2',
      scmj: 9680,
      dkdz: '刘国才',
      dkxz: '林地',
      dknz: '河床',
      dkbz: '林地',
      dkbzxx: null,
      zjrxm: '刘国臣',
      kjzb: '115/114/356/357/358/359/360/355',
      scmjm: 14.52,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 7,
    properties: {
      bsm: 10000006,
      ysdm: '211011',
      dkbm: '1504231032080300024',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '2',
      scmj: 6686.67,
      dkdz: '唐彩玲',
      dkxz: '刘国臣',
      dknz: '河床',
      dkbz: '唐彩玲',
      dkbzxx: null,
      zjrxm: '刘国才',
      kjzb: '111/112/116/113/117/114/115',
      scmjm: 10.03,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 8,
    properties: {
      bsm: 10000007,
      ysdm: '211011',
      dkbm: '1504231032080300017',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '2',
      scmj: 19940,
      dkdz: '刘国才',
      dkxz: '刘国才',
      dknz: '河床',
      dkbz: '田垄',
      dkbzxx: null,
      zjrxm: '唐彩玲',
      kjzb: '112/122/127/123/124/125/128/126/129/113/116',
      scmjm: 29.91,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 9,
    properties: {
      bsm: 10000008,
      ysdm: '211011',
      dkbm: '1504231032080300009',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '2',
      scmj: 5573.33,
      dkdz: '唐彩玲',
      dkxz: '林地',
      dknz: '刘国才',
      dkbz: '林地',
      dkbzxx: null,
      zjrxm: '李占军',
      kjzb: '354/122/112/111/353',
      scmjm: 8.36,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 10,
    properties: {
      bsm: 10000009,
      ysdm: '211011',
      dkbm: '1504231032080300036',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '2',
      scmj: 2453.33,
      dkdz: '道路',
      dkxz: '唐彩玲',
      dknz: '唐彩玲',
      dkbz: '李占军',
      dkbzxx: null,
      zjrxm: '刘国才',
      kjzb: '128/125/361/362/126',
      scmjm: 3.68,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 11,
    properties: {
      bsm: 10000010,
      ysdm: '211011',
      dkbm: '1504231032080300033',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2673.33,
      dkdz: '道路',
      dkxz: '唐彩玲',
      dknz: '刘国才',
      dkbz: '刘海刚',
      dkbzxx: null,
      zjrxm: '李占军',
      kjzb: '125/124/363/361',
      scmjm: 4.01,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 12,
    properties: {
      bsm: 10000011,
      ysdm: '211011',
      dkbm: '1504231032080300032',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 893.33,
      dkdz: '道路',
      dkxz: '唐彩玲',
      dknz: '李占军',
      dkbz: '林地',
      dkbzxx: null,
      zjrxm: '刘海刚',
      kjzb: '124/123/364/363',
      scmjm: 1.34,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 13,
    properties: {
      bsm: 10000012,
      ysdm: '211011',
      dkbm: '1504231032080300012',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '2',
      scmj: 13620,
      dkdz: '孙富',
      dkxz: '田垄',
      dknz: '林地',
      dkbz: '林地',
      dkbzxx: null,
      zjrxm: '李刚',
      kjzb: '104/103/109/110/107/108',
      scmjm: 20.43,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 14,
    properties: {
      bsm: 10000013,
      ysdm: '211011',
      dkbm: '1504231032080300030',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 6006.67,
      dkdz: '林地',
      dkxz: '李刚',
      dknz: '林地',
      dkbz: '林地',
      dkbzxx: null,
      zjrxm: '孙富',
      kjzb: '106/103/104/105',
      scmjm: 9.01,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 15,
    properties: {
      bsm: 10000014,
      ysdm: '211011',
      dkbm: '1504231032080300179',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 4666.67,
      dkdz: '李子良',
      dkxz: '林地',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘海江',
      kjzb: '373/374/376/375',
      scmjm: 7,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 16,
    properties: {
      bsm: 10000015,
      ysdm: '211011',
      dkbm: '1504231032080300020',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 966.67,
      dkdz: '李占海',
      dkxz: '刘海江',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '李子良',
      kjzb: '367/366/374/373',
      scmjm: 1.45,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 17,
    properties: {
      bsm: 10000016,
      ysdm: '211011',
      dkbm: '1504231032080300018',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 1680,
      dkdz: '李占军',
      dkxz: '李子良',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '李占海',
      kjzb: '365/366/367/368',
      scmjm: 2.52,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 18,
    properties: {
      bsm: 10000017,
      ysdm: '211011',
      dkbm: '1504231032080300021',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2193.33,
      dkdz: '李子良',
      dkxz: '李占海',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '李占军',
      kjzb: '369/370/365/368',
      scmjm: 3.29,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 19,
    properties: {
      bsm: 10000018,
      ysdm: '211011',
      dkbm: '1504231032080300022',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 4220,
      dkdz: '于凤才',
      dkxz: '李占军',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '李子良',
      kjzb: '61/64/370/369',
      scmjm: 6.33,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 20,
    properties: {
      bsm: 10000019,
      ysdm: '211011',
      dkbm: '1504231032080300006',
      dkmc: '房前地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 3653.33,
      dkdz: '刘海燕',
      dkxz: '林地',
      dknz: '林地',
      dkbz: '林地',
      dkbzxx: null,
      zjrxm: '刘海波',
      kjzb: '58/57/60/59',
      scmjm: 5.48,
      dldj: '01',
      name: '房前地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 21,
    properties: {
      bsm: 10000020,
      ysdm: '211011',
      dkbm: '1504231032080300014',
      dkmc: '房前地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '2',
      scmj: 1753.33,
      dkdz: '林地',
      dkxz: '林地',
      dknz: '林地',
      dkbz: '林地',
      dkbzxx: null,
      zjrxm: '唐彩玲',
      kjzb: '442/443/444/445/438/439/440/441',
      scmjm: 2.63,
      dldj: '01',
      name: '房前地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 22,
    properties: {
      bsm: 10000021,
      ysdm: '211011',
      dkbm: '1504231032080300007',
      dkmc: '房前地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 4400,
      dkdz: '刘海江',
      dkxz: '刘海波',
      dknz: '林地',
      dkbz: '林地',
      dkbzxx: null,
      zjrxm: '刘海燕',
      kjzb: '56/55/57/58',
      scmjm: 6.6,
      dldj: '01',
      name: '房前地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 23,
    properties: {
      bsm: 10000022,
      ysdm: '211011',
      dkbm: '1504231032080300008',
      dkmc: '房前地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2106.67,
      dkdz: '刘海山',
      dkxz: '刘海燕',
      dknz: '林地',
      dkbz: '林地',
      dkbzxx: null,
      zjrxm: '刘海江',
      kjzb: '49/48/55/56',
      scmjm: 3.16,
      dldj: '01',
      name: '房前地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 24,
    properties: {
      bsm: 10000023,
      ysdm: '211011',
      dkbm: '1504231032080300023',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 5826.67,
      dkdz: '崔万富',
      dkxz: '李子良',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '于凤才',
      kjzb: '61/62/63/64',
      scmjm: 8.74,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 25,
    properties: {
      bsm: 10000024,
      ysdm: '211011',
      dkbm: '1504231032080300025',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 1200,
      dkdz: '侯明铁',
      dkxz: '于凤才',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '崔万富',
      kjzb: '62/65/66/63',
      scmjm: 1.8,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 26,
    properties: {
      bsm: 10000025,
      ysdm: '211011',
      dkbm: '1504231032080300026',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 8313.33,
      dkdz: '刘海燕',
      dkxz: '崔万富',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '侯明铁',
      kjzb: '65/67/68/66',
      scmjm: 12.47,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 27,
    properties: {
      bsm: 10000026,
      ysdm: '211011',
      dkbm: '1504231032080300027',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2166.67,
      dkdz: '刘国田',
      dkxz: '侯明铁',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘海燕',
      kjzb: '67/69/70/68',
      scmjm: 3.25,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 28,
    properties: {
      bsm: 10000027,
      ysdm: '211011',
      dkbm: '1504231032080300029',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 5960,
      dkdz: '于国刚',
      dkxz: '刘海燕',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘国田',
      kjzb: '69/71/72/70',
      scmjm: 8.94,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 29,
    properties: {
      bsm: 10000028,
      ysdm: '211011',
      dkbm: '1504231032080300031',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 5793.33,
      dkdz: '王玉华',
      dkxz: '刘国田',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '于国刚',
      kjzb: '71/73/74/72',
      scmjm: 8.69,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 30,
    properties: {
      bsm: 10000029,
      ysdm: '211011',
      dkbm: '1504231032080300034',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 3526.67,
      dkdz: '刘海刚',
      dkxz: '于国刚',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '王玉华',
      kjzb: '73/75/76/74',
      scmjm: 5.29,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 31,
    properties: {
      bsm: 10000030,
      ysdm: '211011',
      dkbm: '1504231032080300035',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2593.33,
      dkdz: '刘国军',
      dkxz: '王玉华',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘海刚',
      kjzb: '75/77/78/76',
      scmjm: 3.89,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 32,
    properties: {
      bsm: 10000031,
      ysdm: '211011',
      dkbm: '1504231032080300037',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 5140,
      dkdz: '刘国发',
      dkxz: '刘海刚',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘国军',
      kjzb: '77/79/80/78',
      scmjm: 7.71,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 33,
    properties: {
      bsm: 10000032,
      ysdm: '211011',
      dkbm: '1504231032080300155',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2286.67,
      dkdz: '王朝金',
      dkxz: '王久文',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '王玉华',
      kjzb: '156/160/161/157',
      scmjm: 3.43,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 34,
    properties: {
      bsm: 10000033,
      ysdm: '211011',
      dkbm: '1504231032080300149',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 4406.67,
      dkdz: '王玉华',
      dkxz: '河床',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '王久文',
      kjzb: '159/156/157/158',
      scmjm: 6.61,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 35,
    properties: {
      bsm: 10000034,
      ysdm: '211011',
      dkbm: '1504231032080300038',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 5493.33,
      dkdz: '唐彩玲',
      dkxz: '刘国军',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘国发',
      kjzb: '79/81/82/80',
      scmjm: 8.24,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 36,
    properties: {
      bsm: 10000035,
      ysdm: '211011',
      dkbm: '1504231032080300158',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 3226.67,
      dkdz: '刘海刚',
      dkxz: '王玉华',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '王朝金',
      kjzb: '160/162/163/161',
      scmjm: 4.84,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 37,
    properties: {
      bsm: 10000036,
      ysdm: '211011',
      dkbm: '1504231032080300011',
      dkmc: '房前地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 7006.67,
      dkdz: '侯明铁',
      dkxz: '刘海江',
      dknz: '道路',
      dkbz: '林地',
      dkbzxx: null,
      zjrxm: '刘海山',
      kjzb: '47/53/54/48/49/51/52/50',
      scmjm: 10.51,
      dldj: '01',
      name: '房前地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 38,
    properties: {
      bsm: 10000037,
      ysdm: '211011',
      dkbm: '1504231032080300161',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 4333.33,
      dkdz: '孙友',
      dkxz: '王朝金',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘海刚',
      kjzb: '162/164/165/163',
      scmjm: 6.5,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 39,
    properties: {
      bsm: 10000038,
      ysdm: '211011',
      dkbm: '1504231032080300039',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 3140,
      dkdz: '刘海江',
      dkxz: '刘国发',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '唐彩玲',
      kjzb: '81/83/84/82',
      scmjm: 4.71,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 40,
    properties: {
      bsm: 10000039,
      ysdm: '211011',
      dkbm: '1504231032080300040',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2446.67,
      dkdz: '肖理',
      dkxz: '唐彩玲',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘海江',
      kjzb: '83/85/86/84',
      scmjm: 3.67,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 41,
    properties: {
      bsm: 10000040,
      ysdm: '211011',
      dkbm: '1504231032080300165',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 3186.67,
      dkdz: '孙富',
      dkxz: '刘海刚',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '孙友',
      kjzb: '164/166/167/165',
      scmjm: 4.78,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 42,
    properties: {
      bsm: 10000041,
      ysdm: '211011',
      dkbm: '1504231032080300041',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 3453.33,
      dkdz: '孙友',
      dkxz: '刘海江',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '肖理',
      kjzb: '85/87/88/86',
      scmjm: 5.18,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 43,
    properties: {
      bsm: 10000042,
      ysdm: '211011',
      dkbm: '1504231032080300043',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2426.67,
      dkdz: '孙占才',
      dkxz: '肖理',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '孙友',
      kjzb: '87/89/90/88',
      scmjm: 3.64,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 44,
    properties: {
      bsm: 10000043,
      ysdm: '211011',
      dkbm: '1504231032080300167',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2040,
      dkdz: '刘国军',
      dkxz: '孙友',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '孙富',
      kjzb: '166/168/169/167',
      scmjm: 3.06,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 45,
    properties: {
      bsm: 10000044,
      ysdm: '211011',
      dkbm: '1504231032080300132',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 1740,
      dkdz: '刘国才',
      dkxz: '孙富',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘国军',
      kjzb: '168/170/171/169',
      scmjm: 2.61,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 46,
    properties: {
      bsm: 10000045,
      ysdm: '211011',
      dkbm: '1504231032080300044',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2300,
      dkdz: '孙富',
      dkxz: '孙友',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '孙占才',
      kjzb: '89/91/92/90',
      scmjm: 3.45,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 47,
    properties: {
      bsm: 10000046,
      ysdm: '211011',
      dkbm: '1504231032080300134',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2780,
      dkdz: '于凤才',
      dkxz: '刘国军',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘国才',
      kjzb: '170/172/173/171',
      scmjm: 4.17,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 48,
    properties: {
      bsm: 10000047,
      ysdm: '211011',
      dkbm: '1504231032080300045',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 3586.67,
      dkdz: '崔万金',
      dkxz: '孙占才',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '孙富',
      kjzb: '92/91/93/94',
      scmjm: 5.38,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 49,
    properties: {
      bsm: 10000048,
      ysdm: '211011',
      dkbm: '1504231032080300019',
      dkmc: '房前地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 3986.67,
      dkdz: '林地',
      dkxz: '道路',
      dknz: '林地',
      dkbz: '刘海山',
      dkbzxx: null,
      zjrxm: '侯明铁',
      kjzb: '50/101/102/47',
      scmjm: 5.98,
      dldj: '01',
      name: '房前地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 50,
    properties: {
      bsm: 10000049,
      ysdm: '211011',
      dkbm: '1504231032080300136',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2546.67,
      dkdz: '刘海江',
      dkxz: '刘国才',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '于凤才',
      kjzb: '172/174/175/173',
      scmjm: 3.82,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 51,
    properties: {
      bsm: 10000050,
      ysdm: '211011',
      dkbm: '1504231032080300046',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 4753.33,
      dkdz: '李士友',
      dkxz: '孙富',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '崔万金',
      kjzb: '93/95/96/94',
      scmjm: 7.13,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 52,
    properties: {
      bsm: 10000051,
      ysdm: '211011',
      dkbm: '1504231032080300137',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 1346.67,
      dkdz: '刘海波',
      dkxz: '于凤才',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘海江',
      kjzb: '174/176/177/175',
      scmjm: 2.02,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 53,
    properties: {
      bsm: 10000052,
      ysdm: '211011',
      dkbm: '1504231032080300139',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 1460,
      dkdz: '刘海山',
      dkxz: '刘海江',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘海波',
      kjzb: '176/178/179/177',
      scmjm: 2.19,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 54,
    properties: {
      bsm: 10000053,
      ysdm: '211011',
      dkbm: '1504231032080300140',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 1833.33,
      dkdz: '肖国臣',
      dkxz: '刘海波',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘海山',
      kjzb: '178/180/181/179',
      scmjm: 2.75,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 55,
    properties: {
      bsm: 10000054,
      ysdm: '211011',
      dkbm: '1504231032080300047',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 7053.33,
      dkdz: '刘国军',
      dkxz: '崔万金',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '李士友',
      kjzb: '96/95/97/98',
      scmjm: 10.58,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 56,
    properties: {
      bsm: 10000055,
      ysdm: '211011',
      dkbm: '1504231032080300143',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 1580,
      dkdz: '唐彩珍',
      dkxz: '刘海山',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '肖国臣',
      kjzb: '180/182/183/181',
      scmjm: 2.37,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 57,
    properties: {
      bsm: 10000056,
      ysdm: '211011',
      dkbm: '1504231032080300146',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2206.67,
      dkdz: '唐彩玲',
      dkxz: '肖国臣',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '唐彩珍',
      kjzb: '182/184/185/183',
      scmjm: 3.31,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 58,
    properties: {
      bsm: 10000057,
      ysdm: '211011',
      dkbm: '1504231032080300147',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 760,
      dkdz: '于永起',
      dkxz: '唐彩珍',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '唐彩玲',
      kjzb: '184/186/187/185',
      scmjm: 1.14,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 59,
    properties: {
      bsm: 10000058,
      ysdm: '211011',
      dkbm: '1504231032080300148',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 1193.33,
      dkdz: '崔万金',
      dkxz: '唐彩玲',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '于永起',
      kjzb: '186/188/189/187',
      scmjm: 1.79,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 60,
    properties: {
      bsm: 10000059,
      ysdm: '211011',
      dkbm: '1504231032080300150',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 3086.67,
      dkdz: '崔万富',
      dkxz: '于永起',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '崔万金',
      kjzb: '188/190/191/189',
      scmjm: 4.63,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 61,
    properties: {
      bsm: 10000060,
      ysdm: '211011',
      dkbm: '1504231032080300151',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2860,
      dkdz: '侯明铁',
      dkxz: '崔万金',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '崔万富',
      kjzb: '190/192/193/191',
      scmjm: 4.29,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 62,
    properties: {
      bsm: 10000061,
      ysdm: '211011',
      dkbm: '1504231032080300152',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 8713.33,
      dkdz: '刘国发',
      dkxz: '崔万富',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '侯明铁',
      kjzb: '192/194/195/193',
      scmjm: 13.07,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 63,
    properties: {
      bsm: 10000062,
      ysdm: '211011',
      dkbm: '1504231032080300048',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 7526.67,
      dkdz: '李刚',
      dkxz: '李士友',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘国军',
      kjzb: '97/99/100/98',
      scmjm: 11.29,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 64,
    properties: {
      bsm: 10000063,
      ysdm: '211011',
      dkbm: '1504231032080300153',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 3360,
      dkdz: '唐彩玲',
      dkxz: '侯明铁',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘国发',
      kjzb: '195/194/196/197',
      scmjm: 5.04,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 65,
    properties: {
      bsm: 10000064,
      ysdm: '211011',
      dkbm: '1504231032080300154',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2813.33,
      dkdz: '肖国发',
      dkxz: '刘国发',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '唐彩玲',
      kjzb: '196/198/199/197',
      scmjm: 4.22,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 66,
    properties: {
      bsm: 10000065,
      ysdm: '211011',
      dkbm: '1504231032080300156',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2046.67,
      dkdz: '于国刚',
      dkxz: '唐彩玲',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '肖国发',
      kjzb: '199/198/200/201',
      scmjm: 3.07,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 67,
    properties: {
      bsm: 10000066,
      ysdm: '211011',
      dkbm: '1504231032080300049',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2806.67,
      dkdz: '崔万富',
      dkxz: '刘国军',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '李刚',
      kjzb: '100/99/1/4',
      scmjm: 4.21,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 68,
    properties: {
      bsm: 10000067,
      ysdm: '211011',
      dkbm: '1504231032080300157',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2933.33,
      dkdz: '肖理',
      dkxz: '肖国发',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '于国刚',
      kjzb: '371/372/201/200',
      scmjm: 4.4,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 69,
    properties: {
      bsm: 10000068,
      ysdm: '211011',
      dkbm: '1504231032080300159',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2913.33,
      dkdz: '李占海',
      dkxz: '于国刚',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '肖理',
      kjzb: '205/204/372/371',
      scmjm: 4.37,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 70,
    properties: {
      bsm: 10000069,
      ysdm: '211011',
      dkbm: '1504231032080300050',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 3613.33,
      dkdz: '李刚',
      dkxz: '李刚',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '崔万富',
      kjzb: '1/2/3/4',
      scmjm: 5.42,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 71,
    properties: {
      bsm: 10000070,
      ysdm: '211011',
      dkbm: '1504231032080300160',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2800,
      dkdz: '李刚',
      dkxz: '肖理',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '李占海',
      kjzb: '202/203/204/205',
      scmjm: 4.2,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 72,
    properties: {
      bsm: 10000071,
      ysdm: '211011',
      dkbm: '1504231032080300051',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 4773.33,
      dkdz: '陈桂凤',
      dkxz: '崔万富',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '李刚',
      kjzb: '2/5/6/3',
      scmjm: 7.16,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 73,
    properties: {
      bsm: 10000072,
      ysdm: '211011',
      dkbm: '1504231032080300015',
      dkmc: '房前地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 3986.67,
      dkdz: '道路',
      dkxz: '林地',
      dknz: '林地',
      dkbz: '林地',
      dkbzxx: null,
      zjrxm: '刘国军',
      kjzb: '390/391/392/393/394/389',
      scmjm: 5.98,
      dldj: '01',
      name: '房前地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 74,
    properties: {
      bsm: 10000073,
      ysdm: '211011',
      dkbm: '1504231032080300052',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 4753.33,
      dkdz: '肖国发',
      dkxz: '李刚',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '陈桂凤',
      kjzb: '6/5/7/8',
      scmjm: 7.13,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 75,
    properties: {
      bsm: 10000074,
      ysdm: '211011',
      dkbm: '1504231032080300010',
      dkmc: '房前地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 11080,
      dkdz: '林地',
      dkxz: '道路',
      dknz: '林地',
      dkbz: '草地',
      dkbzxx: null,
      zjrxm: '高庆林',
      kjzb: '398/395/396/397',
      scmjm: 16.62,
      dldj: '01',
      name: '房前地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 76,
    properties: {
      bsm: 10000075,
      ysdm: '211011',
      dkbm: '1504231032080300163',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 5873.33,
      dkdz: '田桂林',
      dkxz: '李占海',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '李刚',
      kjzb: '206/207/203/202',
      scmjm: 8.81,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 77,
    properties: {
      bsm: 10000076,
      ysdm: '211011',
      dkbm: '1504231032080300164',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 3453.33,
      dkdz: '孙占武',
      dkxz: '李刚',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '田桂林',
      kjzb: '206/208/209/207',
      scmjm: 5.18,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 78,
    properties: {
      bsm: 10000077,
      ysdm: '211011',
      dkbm: '1504231032080300054',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 3660,
      dkdz: '刘海波',
      dkxz: '陈桂凤',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '肖国发',
      kjzb: '7/9/10/8',
      scmjm: 5.49,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 79,
    properties: {
      bsm: 10000078,
      ysdm: '211011',
      dkbm: '1504231032080300166',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 4940,
      dkdz: '李占军',
      dkxz: '田桂林',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '孙占武',
      kjzb: '209/208/210/211',
      scmjm: 7.41,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 80,
    properties: {
      bsm: 10000079,
      ysdm: '211011',
      dkbm: '1504231032080300055',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 3686.67,
      dkdz: '刘海燕',
      dkxz: '肖国发',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘海波',
      kjzb: '10/9/11/12',
      scmjm: 5.53,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 81,
    properties: {
      bsm: 10000080,
      ysdm: '211011',
      dkbm: '1504231032080300168',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2660,
      dkdz: '刘海龙',
      dkxz: '孙占武',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '李占军',
      kjzb: '210/212/213/211',
      scmjm: 3.99,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 82,
    properties: {
      bsm: 10000081,
      ysdm: '211011',
      dkbm: '1504231032080300056',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 1066.67,
      dkdz: '刘海江',
      dkxz: '刘海波',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘海燕',
      kjzb: '11/13/14/12',
      scmjm: 1.6,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 83,
    properties: {
      bsm: 10000082,
      ysdm: '211011',
      dkbm: '1504231032080300057',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 1220,
      dkdz: '刘海山',
      dkxz: '刘海燕',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘海江',
      kjzb: '14/13/15/16',
      scmjm: 1.83,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 84,
    properties: {
      bsm: 10000083,
      ysdm: '211011',
      dkbm: '1504231032080300058',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 4560,
      dkdz: '孙占武',
      dkxz: '刘海江',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘海山',
      kjzb: '15/17/18/16',
      scmjm: 6.84,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 85,
    properties: {
      bsm: 10000084,
      ysdm: '211011',
      dkbm: '1504231032080300169',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 2360,
      dkdz: '刘国臣',
      dkxz: '李占军',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘海龙',
      kjzb: '212/214/215/213',
      scmjm: 3.54,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 86,
    properties: {
      bsm: 10000085,
      ysdm: '211011',
      dkbm: '1504231032080300170',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '2',
      scmj: 2786.67,
      dkdz: '田桂林',
      dkxz: '刘海龙',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘国臣',
      kjzb: '215/214/216/217',
      scmjm: 4.18,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 87,
    properties: {
      bsm: 10000086,
      ysdm: '211011',
      dkbm: '1504231032080300060',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 6873.33,
      dkdz: '刘国才',
      dkxz: '刘海山',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '孙占武',
      kjzb: '18/17/19/20',
      scmjm: 10.31,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 88,
    properties: {
      bsm: 10000087,
      ysdm: '211011',
      dkbm: '1504231032080300171',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '2',
      scmj: 4773.33,
      dkdz: '肖国发',
      dkxz: '刘国臣',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '田桂林',
      kjzb: '216/218/219/217',
      scmjm: 7.16,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 89,
    properties: {
      bsm: 10000088,
      ysdm: '211011',
      dkbm: '1504231032080300061',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 6993.33,
      dkdz: '刘国臣',
      dkxz: '孙占武',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘国才',
      kjzb: '19/21/22/20',
      scmjm: 10.49,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 90,
    properties: {
      bsm: 10000089,
      ysdm: '211011',
      dkbm: '1504231032080300172',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '2',
      scmj: 2486.67,
      dkdz: '肖理',
      dkxz: '田桂林',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '肖国发',
      kjzb: '219/218/220/221',
      scmjm: 3.73,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 91,
    properties: {
      bsm: 10000090,
      ysdm: '211011',
      dkbm: '1504231032080300063',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 5900,
      dkdz: '田桂林',
      dkxz: '刘国才',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '刘国臣',
      kjzb: '23/22/21/24',
      scmjm: 8.85,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 92,
    properties: {
      bsm: 10000091,
      ysdm: '211011',
      dkbm: '1504231032080300174',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '2',
      scmj: 4280,
      dkdz: '于国刚',
      dkxz: '肖国发',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '肖理',
      kjzb: '222/223/221/220',
      scmjm: 6.42,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 93,
    properties: {
      bsm: 10000092,
      ysdm: '211011',
      dkbm: '1504231032080300064',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 5706.67,
      dkdz: '孙友',
      dkxz: '刘国臣',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '田桂林',
      kjzb: '25/26/23/24',
      scmjm: 8.56,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 94,
    properties: {
      bsm: 10000093,
      ysdm: '211011',
      dkbm: '1504231032080300175',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '2',
      scmj: 4286.67,
      dkdz: '李占海',
      dkxz: '肖理',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '于国刚',
      kjzb: '225/224/223/222',
      scmjm: 6.43,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 95,
    properties: {
      bsm: 10000094,
      ysdm: '211011',
      dkbm: '1504231032080300065',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 1233.33,
      dkdz: '高庆林',
      dkxz: '田桂林',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '孙友',
      kjzb: '26/25/27/28',
      scmjm: 1.85,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 96,
    properties: {
      bsm: 10000095,
      ysdm: '211011',
      dkbm: '1504231032080300066',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 4593.33,
      dkdz: '肖国臣',
      dkxz: '孙友',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '高庆林',
      kjzb: '27/29/30/28',
      scmjm: 6.89,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 97,
    properties: {
      bsm: 10000096,
      ysdm: '211011',
      dkbm: '1504231032080300176',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '2',
      scmj: 2480,
      dkdz: '李刚',
      dkxz: '于国刚',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '李占海',
      kjzb: '224/225/119/118',
      scmjm: 3.72,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 98,
    properties: {
      bsm: 10000097,
      ysdm: '211011',
      dkbm: '1504231032080300177',
      dkmc: '大圈子地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '2',
      scmj: 7606.67,
      dkdz: '草地',
      dkxz: '李占海',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '李刚',
      kjzb: '118/119/120/121',
      scmjm: 11.41,
      dldj: '01',
      name: '大圈子地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 99,
    properties: {
      bsm: 10000098,
      ysdm: '211011',
      dkbm: '1504231032080300067',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 4306.67,
      dkdz: '唐彩珍',
      dkxz: '高庆林',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '肖国臣',
      kjzb: '30/29/31/32',
      scmjm: 6.46,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  },
  {
    type: 'Feature',
    id: 100,
    properties: {
      bsm: 10000099,
      ysdm: '211011',
      dkbm: '1504231032080300068',
      dkmc: '麦地',
      syqxz: '30',
      dklb: '10',
      tdlylx: '012',
      tdyt: '1',
      sfjbnt: '1',
      scmj: 3726.67,
      dkdz: '王朝财',
      dkxz: '肖国臣',
      dknz: '道路',
      dkbz: '道路',
      dkbzxx: null,
      zjrxm: '唐彩珍',
      kjzb: '31/33/34/32',
      scmjm: 5.59,
      dldj: '01',
      name: '麦地'
    },
    geometry: { type: 'MultiPolygon', coordinates: [Array] }
  }
]

let geoCoordMap = {};

let data = [
  { dkmc: "树林北", x: 21 },
  { dkmc: "麦地", x: 21 },
];

let convertData = function (data) {
  let res = [];
  for (let i = 0; i < data.length; i++) {
    let geoCoord = geoCoordMap[data[i].dkmc];

    if (geoCoord) {
      res.push({
        name: data[i].name,
      });
    }
  }
  return res;
};

let max = 6000,
  min = 10;
let maxSize4Pin = 100,
  minSize4Pin = 20;

export default {
  name: "TuDiLiuZhuanMap",
  props: {
    value: Object,
    ispreview: Boolean,
  },

  data() {
    return {
      xiangZhenTongJi: [],  // 乡镇统计
      gaChaCunTongJi: [],   // 嘎查村统计
      oneGaChaCunTongJi: [],   // 一个嘎查村统计
      showGaChaCunTongJi: false,
      showOneGaChaCunTongJi: false,

      isShowDialog: false,
      dialogtTitle: '',
      userClick: false,
      dialogTitle: '',
      dialogHamletTitle: '',

      zhuZhaungTuData: {},
      obj: {},

      map: null,
      mapName: '',

      options: {
        //backgroundColor: '#0F1C3C',
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            if (params.value.length > 1) {
              return params.data.name + "" + params.data.value[2];
            } else {
              return params.name;
            }
          },
        },
        geo: {
          map: "tudiliuzhuan",
          show: true,
          roam: false,
          label: {
            emphasis: {
              show: false,
            },
          },
          layoutSize: "80%",
          itemStyle: {
            normal: {
              borderColor: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "#00F6FF",
                  },
                  {
                    offset: 1,
                    color: "#53D9FF",
                  },
                ],
                false
              ),
              borderWidth: 3,
              shadowColor: "rgba(10,76,139,1)",
              shadowOffsetY: 0,
              shadowBlur: 60,
            },
          },
        },
        series: [
          {
            type: "map",
            map: "tudiliuzhuan",
            aspectScale: 0.75,
            label: {
              normal: {
                //调整数值
                position: "right",
                show: false,
                color: "#53D9FF",
                fontSize: 14,
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {
              normal: {
                //地图块颜色
                areaColor: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#073684", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#061E3D", // 100% 处的颜色
                    },
                  ],
                },
                borderColor: "#215495",
                borderWidth: 1,
              },
              //鼠标放置颜色加深
              emphasis: {
                areaColor: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#073684", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#2B91B7", // 100% 处的颜色
                    },
                  ],
                },
              },
            },
            data: data,
          },
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            rippleEffect: {
              brushType: "stroke",
            },
            showEffectOn: "render",
            itemStyle: {
              normal: {
                //气泡颜色
                color: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(5,80,151,0.2)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(5,80,151,0.8)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0,108,255,0.7)",
                    },
                  ],
                  global: false,
                },
              },
            },
            label: {
              normal: {
                show: true,
                color: "#fff",
                fontWeight: "bold",
                position: "inside",
                formatter: function (para) {
                  return "{cnNum|" + para.data.value[2] + "}";
                },
                rich: {
                  cnNum: {
                    fontSize: 13,
                    color: "#D4EEFF",
                  },
                },
              },
            },
            symbol: "circle",
            symbolSize: function (val) {
              if (val[2] == 0) {
                return 0;
              }
              return (
                ((maxSize4Pin - minSize4Pin) / (max - min)) * val[2] +
                (maxSize4Pin -
                  ((maxSize4Pin - minSize4Pin) / (max - min)) * max) *
                  1.2
              );
            },
            data: convertData(data),
            zlevel: 1,
          },
        ],
      },
      optionsStyle: {}, // 样式
      optionsData: {}, // 数据
      optionsCollapse: {}, // 图标属性
      optionsSetup: {},
    };
  },
  computed: {

  },
  watch: {
    value: {
      handler(val) {
        this.optionsStyle = val.position;
        this.optionsData = val.data;
        this.optionsCollapse = val.setup;
        this.optionsSetup = val.setup;
        this.editorOptions();
      },
      deep: true,
    },
  },
  mounted() {
    this.optionsStyle = this.value.position;
    this.optionsData = this.value.data;
    this.optionsCollapse = this.value.setup;
    this.optionsSetup = this.value.setup;
    // this.editorOptions();
  },
  methods: {
    
    // 点击 map
    clickMap(e) {
      console.log('土地流转 : ', e);
      for (let i in arr) {
        if (arr[i].properties.bsm == e.name) {
          this.$emit('showObj',  arr[i].properties)
          return true
        }
      } 
    },

    // 跳转到详情页面
    goToDetail(val) {
      // 方案1
      this.$router.push({
        path: "/bigscreen/detail/" + val
      })

      // 方案2
      // localStorage.setItem('village', val)
    },

    // 修改图标options属性
    editorOptions() {
      this.setOptionsTitle();
      this.setOptionTextValue();
      this.setOptionsData();
      this.setOptionAirSize();
      this.setOptionMapBlocak();
    },
    // 标题设置
    setOptionsTitle() {
      const optionsSetup = this.optionsSetup;
      const title = {};
      title.text = optionsSetup.titleText;
      title.show = optionsSetup.isNoTitle;
      title.left = optionsSetup.textAlign;
      title.textStyle = {
        color: optionsSetup.textColor,
        fontSize: optionsSetup.textFontSize,
        fontWeight: optionsSetup.textFontWeight,
        fontStyle: optionsSetup.textFontStyle,
      };
      title.subtext = optionsSetup.subText;
      title.subtextStyle = {
        color: optionsSetup.subTextColor,
        fontWeight: optionsSetup.subTextFontWeight,
        fontSize: optionsSetup.subTextFontSize,
        fontStyle: optionsSetup.subTextFontStyle,
      };
      this.options.title = title;
    },
    setOptionTextValue() {
      const optionsSetup = this.optionsSetup;
      const label = this.options.series[0]["label"];
      const normal = {
        position: "right",
        show: optionsSetup.isShowMap,
        color: optionsSetup.fontTextColor,
        fontSize: optionsSetup.fontTextSize,
        fontWeight: optionsSetup.fontTextWeight,
      };
      label["normal"] = normal;
    },

    setOptionMapBlocak() {
      const optionsSetup = this.optionsSetup;
      const itemStyle = this.options.series[0]["itemStyle"];
      const normal = {
        //地图块颜色
        areaColor: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: optionsSetup.font0PreColor, // 0% 处的颜色
            },
            {
              offset: 1,
              color: optionsSetup.font100PreColor, // 100% 处的颜色
            },
          ],
        },
        borderColor: "#215495",
        borderWidth: 1,
      };
      //鼠标放置颜色加深
      const emphasis = {
        areaColor: {
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#073684", // 0% 处的颜色
            },
            {
              offset: 1,
              color: optionsSetup.fontHighlightColor, // 100% 处的颜色
            },
          ],
        },
      };
      itemStyle["normal"] = normal;
      itemStyle["emphasis"] = emphasis;
    },
    setOptionAirSize() {
      maxSize4Pin = this.optionsSetup.fontmaxSize4Pin;
      minSize4Pin = this.optionsSetup.fontminSize4Pin;
    },
    //数据解析
    setOptionsData() {
      const optionsData = this.optionsData; // 数据类型 静态 or 动态
      optionsData.dataType == "staticData"
        ? this.staticDataFn(optionsData.staticData)
        : this.dynamicDataFn(optionsData.dynamicData, optionsData.refreshTime);
    },
    staticDataFn(val) {
      this.options.series[0]["data"] = val;
      const optionsSetup = this.optionsSetup;
      const label = this.options.series[1]["label"];
      const normal = {
        show: true,
        color: "#fff",
        fontWeight: "bold",
        position: "inside",
        formatter: function (para) {
          return "{cnNum|" + para.data.value[2] + "}";
        },
        rich: {
          cnNum: {
            fontSize: optionsSetup.fontDataSize,
            color: optionsSetup.fontDataColor,
            fontWeight: optionsSetup.fontDataWeight,
          },
        },
      };
      const data = convertData(val);
      this.options.series[1]["data"] = data;
      label["normal"] = normal;
    },
    dynamicDataFn(val, refreshTime) {
      if (!val) return;
      if (this.ispreview) {
        this.getEchartData(val);
        this.flagInter = setInterval(() => {
          this.getEchartData(val);
        }, refreshTime);
      } else {
        this.getEchartData(val);
      }
    },
    getEchartData(val) {
      const data = this.queryEchartsData(val);
      data.then((res) => {
        this.renderingFn(res);
      });
    },
    renderingFn(val) {
      this.options.series[0]["data"] = val;
      const optionsSetup = this.optionsSetup;
      const label = this.options.series[1]["label"];
      const normal = {
        show: true,
        color: "#fff",
        fontWeight: "bold",
        position: "inside",
        formatter: function (para) {
          return "{cnNum|" + para.data.value[2] + "}";
        },
        rich: {
          cnNum: {
            fontSize: optionsSetup.fontDataSize,
            color: optionsSetup.fontDataColor,
            fontWeight: optionsSetup.fontDataWeight,
          },
        },
      };
      const data = convertData(val);
      this.options.series[1]["data"] = data;
      label["normal"] = normal;
    },
  },
};
</script>
<style lang="scss" scoped>

.ba_lin_you_qi_map {
  width: 100%;
  height: 100%;
}

.echartMap {
  width: 100%;
  height: 100%;
  color: #d4eeff;
}

.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.village {
  font-size: 26px;
  color: #fff;
  position: relative;
  top: 70px;
  left: 70px;
  z-index: 9999999;
}
.village:hover {
  cursor: pointer !important;
  color: #2366fc !important;
}

.tong_ji_dialog {
  width: 900px;
  height: 700px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 51%;
  transform: translate(-50%, -50%);
  z-index: 99999;

  .tong_ji_title {
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: #2366fc;
    color: #fff;
    font-size: 20px;
    
  }

  .tong_ji_list {
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
    // border: 1px solid #f00;
    .tong_ji_item {
      padding: 10px;
      border-bottom: 1px solid #eee;
      .tong_ji_name {
        text-align: left;
        padding: 10px;
        font-size: 18px;
        color: #212121;
        cursor: pointer;
        font-weight: bold;
      }

      span { font-size: 18px; margin-right: 20px; }
    }
  }

  .footer_btn {
    margin-top: 20px;
  }
}

// 询问跳转 dialog
.xun_wen_dialog {
  width: 100%;
  height: 100%;
  background-color: rgba(#212121, .8);
  position: relative;
  top: 0;
  left: 0;

  .xun_wen {
    width: 50%;
    height: 300px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;

    .title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #2366fc;
      color: #fff;
      font-size: 20px;
    }

    .footer_btn {
      margin-top: 20px;
    }
  }
}

.mo-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.el-dialog__wrapper {
  background-color: rgba(#212121, .6);

  .el-dialog {
    margin-top: 47% !important;
  }
}
.el-dialog {  margin-top: 47% !important; }
</style>
